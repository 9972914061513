<template>
  <el-row type="flex" style="margin: 15px 0;">
    <el-col :span="12">
      <!-- <el-input v-model="state.search" style="width: 200px;" placeholder="请输入用户名" /> -->
    </el-col>
    <el-col :span="12" align="right">
      <el-button type="primary" icon="Plus" @click="handleSetting">添加</el-button>
    </el-col>
  </el-row>

  <el-table stripe border style="width: 100%"
    :data="filterTableData" v-loading="state.loading">
    <el-table-column label="序号" align="center" type="index" width="60" />
    <el-table-column label="用户名" prop="realname">
      <template #default="scope">
        <div style="display: flex; align-items: center">
          <el-icon><User /></el-icon>
          <span style="margin-left: 10px">{{ scope.row.realname }}</span>
        </div>
      </template>
    </el-table-column>
    <!-- <el-table-column label="密码" prop="realname" /> -->

    <el-table-column label="权限" prop="roles" width="350">
      <template #default="scope">
      <span v-if="scope?.row?.roles">
        <el-tag type="warning"
          style="margin-right: 10px;"
          v-for="(item, index) of scope.row.roles.split(',')"
          :key="index">
          {{ rolesText[item] }}
        </el-tag>
      </span>
      </template>
    </el-table-column>

    <el-table-column label="创建时间">
      <template #default="scope">
        <div style="display: flex; align-items: center">
          <el-icon><timer /></el-icon>
          <span style="margin-left: 10px">{{ scope.row.create_time }}</span>
        </div>
      </template>
    </el-table-column>

    <el-table-column label="操作" width="190">
      <template #header>
        <el-input v-model="state.search" size="small" placeholder="请输入用户名" />
      </template>

      <template #default="scope">
        <el-button
          size="small"
          icon="Edit"
          @click="handleEdit(scope.row)">
          编辑
        </el-button>
        <el-button
          v-if="scope.row.status !== 1"
          size="small"
          type="success"
          icon="CircleCheck"
          @click="handleLiftABan(scope.row)">
          解禁
        </el-button>
        <el-button
          v-else
          size="small"
          type="danger"
          icon="Warning"
          @click="handleBan(scope.row)">
          封禁
        </el-button>
      </template>
    </el-table-column>
  </el-table>
  <el-pagination
    v-if="state.total"
    style="margin-top: 15px;"
    small
    background
    layout="prev, pager, next"
    :total="state.total"
    :page-size="state.pageOptions.page_size"
    @current-change="currentPageChange"
  />

  <Setting ref="settingRef" @finished="settingFinished"></Setting>
</template>

<script setup>
import { reactive, ref, computed } from 'vue'
import Setting from './components/Setting.vue'
import { ElMessage } from 'element-plus'
import { adminList, adminUpdate } from '@/api'
const settingRef = ref(null)

// 添加权限
function handleSetting () {
  settingRef.value.show()
}

// 表格数据
const state = reactive({
  loading: false,
  search: '',
  pageOptions: {
    page: 1,
    page_size: 50
  },
  total: 0,
  tableData: []
})
const rolesText = { 1: '管理员', 2: '文件列表', 3: '签批领导录入', 4: '秘书专享' }

const filterTableData = computed(() =>
  state.tableData.filter((data) =>
    !state.search || data.realname.toLowerCase().includes(state.search.toLowerCase())
  )
)

// 编辑
function handleEdit (item) {
  settingRef.value.show(JSON.parse(JSON.stringify(item)))
}

// 解禁
function handleLiftABan (item) {
  console.log('解禁', item)
  adminUpdateApi({ id: item.id, status: 1 }, '解禁成功')
}

// 封禁
function handleBan (item) {
  console.log('封禁', item)
  adminUpdateApi({ id: item.id, status: 0 }, '封禁成功')
}

currentPageChange(1)
// 页码改变
function currentPageChange (page) {
  state.pageOptions.page = page || 1
  adminListApi()
}

// 创建 | 修改管理员成功回调
function settingFinished () {
  adminListApi()
}

// 获取权限管理列表接口
function adminListApi () {
  state.loading = true
  adminList(state.pageOptions).then(res => {
    state.tableData = res.data
    state.total = res.total
    state.loading = false
  })
}

// 更新管理员接口
function adminUpdateApi (params, notice) {
  adminUpdate(params).then(res => {
    ElMessage({
      type: 'success',
      message: notice || '操作成功'
    })
    adminListApi()
  })
}
</script>

<style lang="scss" scoped>
</style>
