<template>
  <el-dialog v-model="state.showSettingDialog" width="30%">
    <el-form
      ref="ruleFormRef"
      :model="state.ruleForm"
      :rules="rules"
      label-width="52px"
      :size="formSize"
      status-icon>
      <el-form-item label="账号" prop="realname">
        <el-input v-model="state.ruleForm.realname" placeholder="请输入账号" />
      </el-form-item>

      <el-form-item label="密码" prop="password" v-if="!state.disable">
        <el-input v-model="state.ruleForm.password" placeholder="请输入密码" />
      </el-form-item>

      <el-form-item label="密码" v-else>
        <el-input v-model="state.ruleForm.password" placeholder="不输入密码密码不会更改" />
      </el-form-item>

      <el-form-item label="科室" prop="department_id">
        <el-select v-model="state.ruleForm.department_id" placeholder="请选择科室"
          @change="departmentChange">
          <el-option
            v-for="(item, index) of state.departmentList"
            :disabled="item.status === 0"
            :key="index"
            :label="item.name"
            :value="item.id">
            <el-tag type="info" v-if="item.status === 0">{{ item.name }}</el-tag>
            <el-tag type="success" v-else>{{ item.name }}</el-tag>
            <span
              v-if="item.status === 0"
              style="float: right; color: var(--el-text-color-secondary);font-size: 13px;">
              已关闭
            </span>
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="权限" prop="roles" v-if="state.ruleForm.department_id">
        <el-checkbox-group
          v-if="state.ruleForm.department_id === 1"
          v-model="state.ruleForm.roles">
          <el-checkbox label="1">管理员</el-checkbox>
          <el-checkbox label="2">文件列表</el-checkbox>
          <el-checkbox label="3">签批领导录入</el-checkbox>
          <el-checkbox label="4">秘书专享</el-checkbox>
        </el-checkbox-group>
        <el-checkbox-group
          v-else-if="state.ruleForm.department_id === 2"
          v-model="state.ruleForm.roles">
          <el-checkbox label="2" :disabled="state.ruleForm.roles.includes('4')">文件列表</el-checkbox>
          <el-checkbox label="4" :disabled="state.ruleForm.roles.includes('2')">秘书专享</el-checkbox>
        </el-checkbox-group>
        <el-checkbox-group
          v-else
          v-model="state.ruleForm.roles">
          <el-checkbox label="2">文件列表</el-checkbox>
        </el-checkbox-group>
      </el-form-item>

      <el-form-item>
        <el-button v-if="!state.disable" @click="resetForm(ruleFormRef)">重置</el-button>
        <el-button type="primary" @click="submitForm(ruleFormRef)">
          {{state.disable ? '确认修改' : '确认添加'}}
        </el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>
<script setup>
import { ref, reactive, defineExpose, defineEmits, watchEffect } from 'vue'
import { ElMessage } from 'element-plus'
import { adminCreate, adminUpdate, getDepartmentList } from '@/api'

const state = reactive({
  showSettingDialog: false,
  departmentList: [],
  ruleForm: {
    id: '',
    realname: '',
    password: '',
    username: '',
    department_id: '',
    roles: []
  },
  disable: false
})

const formSize = ref('default')
const ruleFormRef = ref(null)
const rules = reactive({
  realname: [
    { required: true, message: '请输入账号', trigger: 'blur' }
  ],
  password: [
    { required: true, message: '请输入密码', trigger: 'blur' },
    { min: 6, max: 23, message: '密码应设置为6-23位', trigger: 'blur' }
  ],
  department_id: [{ required: true, message: '请选择科室', trigger: 'change' }],
  roles: [{ required: true, message: '请至少选择一个权限', trigger: 'change' }]
})

watchEffect(() => {
  // if (state.ruleForm.roles.includes('4')) {
  //   state.ruleForm.roles = ['4']
  // }
})

function show (item) {
  state.showSettingDialog = true
  if (item) {
    state.disable = true
    state.ruleForm = item
    state.ruleForm.roles = item?.roles?.split(',')
  } else {
    state.disable = false
    state.ruleForm = {
      realname: '',
      password: '',
      username: '',
      department_id: '',
      roles: []
    }
  }
  ruleFormRef?.value && ruleFormRef.value.clearValidate()
}

function departmentChange () {
  state.ruleForm.roles = []
}

const submitForm = async (formEl) => {
  if (!formEl) return
  await formEl.validate((valid, fields) => {
    if (valid) {
      console.log('submit!', state.ruleForm)
      if (state.disable) {
        adminUpdateApi() // 更新用户
      } else {
        adminCreateApi() // 创建用户
      }
    } else {
      console.log('error submit!', fields)
    }
  })
}

const resetForm = (formEl) => {
  if (!formEl) return
  formEl.resetFields()
}

// 创建管理员接口
function adminCreateApi () {
  const params = {
    realname: state.ruleForm.realname || '',
    password: state.ruleForm.password || '',
    username: state.ruleForm.realname || '',
    department_id: state.ruleForm.department_id || '',
    roles: state.ruleForm.roles.join(',')
  }
  adminCreate(params).then(res => {
    state.showSettingDialog = false
    ElMessage({
      type: 'success',
      message: '创建成功'
    })
    emit('finished')
  }).catch(errmsg => {
    ElMessage({
      type: 'warning',
      message: errmsg
    })
  })
}

// 更新管理员接口
function adminUpdateApi () {
  const params = {
    id: state.ruleForm.id,
    realname: state.ruleForm.realname,
    department_id: state.ruleForm.department_id || '',
    roles: state.ruleForm.roles.join(',')
  }
  if (state?.ruleForm?.password) {
    params.password = state.ruleForm.password
  }
  adminUpdate(params).then(res => {
    state.showSettingDialog = false
    ElMessage({
      type: 'success',
      message: '编辑成功'
    })
    emit('finished')
  })
}

getDepartmentList().then(res => {
  console.log('科室列表', res)
  state.departmentList = res
})

const emit = defineEmits([
  'finished'
])

defineExpose({
  state,
  show
})
</script>

<style scoped>
.footer_text {
  margin-right: 20px;
}
</style>
